import { createInternalLink } from "~utils/link_utils/createInternalLink";

import type { CardProps } from "~components/Card";
import type { SbRelationsGraphQlNode } from "~types/storyblok.types";

export function transformStoryblokEntryNodeToCard(
  node: SbRelationsGraphQlNode
): CardProps {
  const { fields, full_slug, uuid } = node;

  const link = createInternalLink(full_slug);

  const { pageMetadata, ...restFields } = fields || {};

  return {
    link: fields?.linkToExternalPage || link,
    image: fields?.cover,
    ...restFields,
    ...pageMetadata,
    _uid: uuid,
  };
}
