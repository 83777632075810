import type { DropdownItemShape } from "~types/global.types";
import type { SbRelationsGraphQlNode } from "~types/storyblok.types";

export function transformStoryblokEntryNodeToDropdownItem({
  fields,
  full_slug,
}: SbRelationsGraphQlNode): DropdownItemShape {
  const { pageMetadata } = fields || {};

  const { title } = pageMetadata || {};

  return {
    label: title || "",

    value: title || "",

    link: full_slug,
  };
}
