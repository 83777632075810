export const ANIMATED = {
  slider: "slider",
  circle: "circle",
  dottedPaths: "dottedPaths",
  groupPrimary: "groupPrimary",
  groupSecondary: "groupSecondary",
  logo: "logo",
  mask: "mask",
  path: "path",
  primary: "primary",
  progress: "progress",
  secondary: "secondary",
  video: "progress",
} as const;

export type MotionAttrEnum = (typeof ANIMATED)[keyof typeof ANIMATED];

export const getMotionSelector = (motionAttr: MotionAttrEnum) => {
  return `[data-motion='${motionAttr}']`;
};

/**
 * ------------------------------------------------------------------------------
 * Util function for getting single element within a ref by query selector
 * ------------------------------------------------------------------------------
 */

export const getSingleElemByMotionAttr = <
  TElementType extends Element = HTMLDivElement
>(
  ref: React.RefObject<HTMLDivElement>,
  motionAttr: (typeof ANIMATED)[keyof typeof ANIMATED]
) => {
  if (!ref?.current) {
    return [];
  }

  return ref.current?.querySelector<TElementType>(
    getMotionSelector(motionAttr)
  );
};

/**
 * ------------------------------------------------------------------------------
 * Util function for getting elements within a ref by query selector
 * ------------------------------------------------------------------------------
 */

export const getElemsByMotionAttr = <
  TElementType extends Element = HTMLDivElement
>(
  ref: React.RefObject<HTMLDivElement>,
  motionAttr: (typeof ANIMATED)[keyof typeof ANIMATED]
) => {
  if (!ref?.current) {
    return [];
  }

  return ref.current?.querySelectorAll<TElementType>(
    getMotionSelector(motionAttr)
  );
};
