import { createInternalLink } from "~utils/link_utils/createInternalLink";

import type { LinkListItemProps } from "~components/LinkList/LinkListItem";
import type { SbRelationsGraphQlNode } from "~types/storyblok.types";

export function transformStoryblokEntryNodeToLinkListItem({
  fields,
  full_slug,
}: SbRelationsGraphQlNode): LinkListItemProps {
  const link = createInternalLink(full_slug);

  const { pageMetadata, brandIcon, linkToExternalPage } = fields || {};

  const { description, title, prefix } = pageMetadata || {};

  return {
    link: fields?.linkToExternalPage || link,
    brandIcon,
    description,
    linkToExternalPage,
    prefix,
    title,
  };
}
